// extracted by mini-css-extract-plugin
export var main = "style-module--main--DrSBz";
export var wrapper = "style-module--wrapper--WgwVv";
export var col = "style-module--col--x5zmp";
export var titleWrapper = "style-module--titleWrapper--6hbqZ";
export var titleStyle = "style-module--titleStyle--Pclcb";
export var descriptionStyle = "style-module--descriptionStyle--y+axN";
export var contactWrapper = "style-module--contactWrapper--3vk-W";
export var contactLabel = "style-module--contactLabel--eQqCu";
export var contactLabelStyle = "style-module--contactLabelStyle--rN5Qj";
export var contactValue = "style-module--contactValue--P9Vw7";
export var contactValueStyle = "style-module--contactValueStyle--6VdhL";
export var copyright = "style-module--copyright--+hV-S";